import {ComponentType, FC, PropsWithChildren} from 'react';
import {ErrorBoundary as RollbarErrorBoundary} from '@rollbar/react';
import {Configuration, Payload} from "rollbar";

import {getConfig} from "../../_configs/AppConfig";
const {publicRuntimeConfig} = getConfig();

export const rollbarConfig: Configuration = {
    accessToken: publicRuntimeConfig.rollbar.post_client_access_token,
    environment: publicRuntimeConfig.APP_ENV,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: publicRuntimeConfig.APP_ENV === "prod",
    checkIgnore: function(isUncaught, args, payload: Payload) {
        if (isUncaught && payload.body?.trace?.frames && /DangerouslySetHtmlContent/.exec(payload.body.trace.frames[payload.body.trace.frames.length - 1].method)) {
            // uncaught exceptions in DangerouslySetHtmlContent are almost definitely from userGenerated code, but we
            // can't know that in the ErrorBoundary because the error is thrown asynchronously when inserting into the
            // DOM, which is not caught by React Error Boundaries. So if we're here, it's pretty safe to ignore it.
            return true;
        }
        // return true to ignore, otherwise will be reported
        const errorClassesToIgnore: string[] = [
            "ChunkLoadError",
        ];
        const errorMessageRegexesToIgnore: RegExp[] = [
            /^attempted to hard navigate to the same URL/,
            /^Load failed$/,
            /^Unexpected token '<'$/,
            /^\$ is not defined$/,
            /^Can't find variable: \$$/,
            /^Script error\.$/,
        ];

        if (errorClassesToIgnore.includes(payload.body.trace.exception.class)) {
            return true;
        } else if (errorMessageRegexesToIgnore.some(r => r.exec(payload.body.trace.exception.message))) {
            return true;
        }
        return false;
    },
    payload: {
        client: {
            javascript: {
                code_version: publicRuntimeConfig.REACT_FRONTEND_VERSION,
            }
        }
    }
}

const DefaultFallbackUI = ({ error, resetError }) => {
    if (publicRuntimeConfig.APP_ENV === "dev") {
        console.error('error: ', error);
        console.error('resetError: ', resetError);
    }
    return <div className="sb-error-inside-block" style={{padding: "1rem"}}>
        <span className="sb-error-inside-block-icon far fa-frown"/>
        <div className="sb-error-inside-block-header"><h2>Oops, there was an error!</h2></div>
        <p className="sb-error-inside-block-message">
            Don't worry, we're on it.  The error has been logged and we'll work hard to fix it.
        </p>
    </div>
}

export const ErrorBoundary: FC<PropsWithChildren<{
    fallbackUI?: ComponentType<{
        error: Error | null,
        resetError: () => void,
    }>
    userGenerated?: boolean,
}>> = (props) => {
    const extra = {
        user_generated: props.userGenerated ? "true" : "false",
    }

    return <RollbarErrorBoundary fallbackUI={props.fallbackUI || DefaultFallbackUI} extra={extra}>
        {props.children}
    </RollbarErrorBoundary>
}
